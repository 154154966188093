import { Tag } from '@ui';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { LibraryStatus } from '../duck/libraryConstants';

export const LibraryStatusTag = ({ status }: { status: LibraryStatus }) => {
  const { t } = useTranslation(['libraryRoot']);
  const theme = useTheme();

  switch (status) {
    case LibraryStatus.Active:
      return <Tag color={theme.colorSuccess}>{t(`status.${LibraryStatus.Active}`)}</Tag>;
    case LibraryStatus.Development:
      return <Tag color={theme.colorPrimary}>{t(`status.${LibraryStatus.Development}`)}</Tag>;
    case LibraryStatus.Retired:
      return <Tag color={theme.colorError}>{t(`status.${LibraryStatus.Retired}`)}</Tag>;
    default:
      return <Tag color={theme.colorWarning}>{t('na')}</Tag>;
  }
};
