import { Button, Checkbox, Link, Spin, Tooltip } from '@ui';
import { DeleteOutlined, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { CSSObject } from '@emotion/react';
import { startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import dayjs from 'dayjs';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useMessage } from '../duck/hooks/messages';
import { INotification, INotificationId, useArchiveMutation, useReadMutation } from '../duck/notificationsApi';
import { fetchNotifications } from '../duck/notificationsListeners';

export const NotificationItem = ({ notification, selectable, selected, onSelect }: INotificationItem) => {
  const { t } = useTranslation(['shared']);
  const [read] = useReadMutation();
  const [archive] = useArchiveMutation();
  const [archiving, setArchiving] = useState(false);
  const { composeMessage, composeLink, composeAction } = useMessage();
  const message = composeMessage(notification.extra);
  const link = composeLink(notification.extra);
  const action = composeAction(notification.extra);

  const archiveNotification = async () => {
    if (archiving) {
      return;
    }

    setArchiving(true);
    try {
      await archive([parseInt(notification.notificationId)]).unwrap();
      fetchNotifications();
    } catch (e) {
      setArchiving(false);
    }
  };

  const readNotification = async () => {
    if (notification.isRead) {
      return;
    }

    try {
      await read([parseInt(notification.notificationId)]).unwrap();
      fetchNotifications();
    } catch (e) {}
  };

  const handleSelect = (e: CheckboxChangeEvent) => {
    if (onSelect) {
      onSelect(notification.notificationId);
    }
  };

  return (
    <div css={cssItem(message.error, notification.isRead)} onClick={readNotification}>
      <div css={cssContent}>
        <div css={cssHeader}>
          <span css={cssActor}>
            {selectable && (
              <Checkbox
                css={cssCheckbox}
                checked={!!selected}
                onChange={handleSelect}
                onClick={(e) => e.stopPropagation()}
              />
            )}
            {startCase(notification.actorName ?? 'Unknown')}
          </span>
          <span css={cssTime}>
            <Tooltip placement="top" title={dayjs(Number(notification.creationDateTs)).toString()}>
              {dayjs(Number(notification.creationDateTs)).fromNow()}
            </Tooltip>
          </span>
        </div>
        <div css={cssMessage}>
          <span>{message.text}</span>
          {action && (
            <Button css={cssAction} type="link" icon={action.icon} onClick={action.onClick}>
              {action.title}
            </Button>
          )}
        </div>
      </div>
      <div css={cssButtonsLayout}>
        {!notification.isArchived ? (
          <span onClick={archiveNotification}>
            {!archiving && <DeleteOutlined css={cssDeleteButton} title={t('notification.archive')} />}
            {archiving && <Spin indicator={<LoadingOutlined />} size="small" />}
          </span>
        ) : (
          <span>&nbsp;</span>
        )}
        {link && (
          <span>
            <Link to={link} disabled={!link} reloadDocument>
              <InfoCircleOutlined css={cssDetailsButton} title={t('notification.details')} />
            </Link>
          </span>
        )}
      </div>
    </div>
  );
};

interface INotificationItem {
  notification: INotification;
  selectable: boolean;
  selected?: boolean;
  onSelect?: (id: INotificationId) => void;
}

const cssItem =
  (error: boolean, isRead: boolean = true) =>
  (): CSSObject => ({
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '0.5rem',
    padding: '15px 16px 5px',
    borderBottom: '1px solid #d4d4d4',
    backgroundColor: error ? '#ffe9e9' : 'transparent',
    '&:hover': {
      backgroundColor: error ? '#ffbbbb' : 'white',
    },
    borderLeft: !isRead ? '3px solid green' : '3px solid transparent',
  });

const cssContent = (): CSSObject => ({
  width: '100%',
});

const cssHeader = (): CSSObject => ({
  display: 'flex',
  justifyContent: 'space-between',
});

const cssCheckbox = (): CSSObject => ({
  marginRight: '0.5rem',
});

const cssActor = (): CSSObject => ({
  fontWeight: 'bold',
});

const cssTime = (): CSSObject => ({
  fontSize: '0.75rem',
  color: 'gray',
});

const cssMessage = (): CSSObject => ({
  overflowWrap: 'anywhere',
});

const cssAction = (): CSSObject => ({
  float: 'right',
});

const cssButtonsLayout = (): CSSObject => ({
  display: 'grid',
});

const cssDeleteButton = (): CSSObject => ({
  color: 'gray',
  fontSize: '16px',
});

const cssDetailsButton = (): CSSObject => ({
  fontSize: '16px',
});
