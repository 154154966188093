import { AuditLogListPage } from '@modules/audit/containers';
import { ModelRootPage } from '@modules/model/containers';
import routes from '@routes';
import { SourceRootPage } from '@modules/source/containers/SourceRootPage';
import { StoreRootPage } from '@modules/stores/containers';
import { JobRootPage, JobEditPage } from '@modules/job/containers';
import { SnapshotRootPage } from '@modules/snapshot/containers/SnapshotRootPage';
import {
  StudyPage,
  StudyPermissionsPage,
  StudyRootPage,
  StudyInstrumentsPage,
  StudyEventDefinitionsPage,
  StudyNonCrfPage,
  CrossStudyPage,
} from '@modules/study/containers';
import { StudyApp } from '@modules/study/StudyApp';
import { StudyAppRouterProvider } from '@modules/study/StudyAppRouterProvider';
import { CrossViewerDataPage, ViewerDataPage } from '@modules/viewer/containers';
import { ModelConfigurationPage } from '@modules/model/containers';
import { PageNotFound } from '@components';
import { CrossStudyApp } from '@modules/study/CrossStudyApp';
import { GpdipRootPage } from '@modules/gpdip/containers/GpdipRootPage';
import { SnapshotCreatePage } from '@modules/snapshot/containers/SnapshotCreatePage';
import { SnapshotViewPage } from '@modules/snapshot/containers/SnapshotViewPage';
import { DatasetRootPage } from '@modules/dataset/containers';
import { UserRoleGate } from '@modules/user/UserRoleGate';
import { RouteObject } from 'react-router-dom';

const appStudyProviderId = 'study-provider';

export const RoutesStudyApp: RouteObject[] = [
  {
    path: routes.app.root.path,
    id: routes.app.root.id,
    element: <StudyRootPage />,
  },
  {
    path: routes.study.root.path,
    id: appStudyProviderId,
    element: <StudyAppRouterProvider />,
    children: [
      {
        path: routes.study.models.root.path,
        id: routes.study.models.root.id,
        element: (
          <UserRoleGate canDataModelRead>
            <ModelRootPage />
          </UserRoleGate>
        ),
      },
      {
        path: routes.study.models.view.path,
        id: routes.study.models.view.id,
        element: (
          <UserRoleGate canDataModelRead>
            <ModelConfigurationPage />
          </UserRoleGate>
        ),
      },
      {
        path: routes.study.dataViewer.path,
        id: routes.study.dataViewer.id,
        element: <ViewerDataPage />,
      },
      {
        path: routes.study.jobs.root.path,
        id: routes.study.jobs.root.id,
        element: (
          <UserRoleGate canImportJobRead>
            <JobRootPage />
          </UserRoleGate>
        ),
      },
      {
        path: routes.study.jobs.view.path,
        id: routes.study.jobs.view.id,
        element: (
          <UserRoleGate canImportJobRead>
            <JobEditPage />
          </UserRoleGate>
        ),
      },
      {
        path: routes.study.snapshots.root.path,
        id: routes.study.snapshots.root.id,
        element: (
          <UserRoleGate canSnapshotsRead>
            <SnapshotRootPage />
          </UserRoleGate>
        ),
      },
      {
        path: routes.study.gpdip.path,
        id: routes.study.gpdip.id,
        element: <GpdipRootPage />,
      },
      {
        path: routes.study.snapshots.view.path,
        id: routes.study.snapshots.view.id,
        handle: { parentId: routes.study.snapshots.root.id },
        element: (
          <UserRoleGate canSnapshotsRead>
            <SnapshotViewPage />
          </UserRoleGate>
        ),
      },
      {
        path: routes.study.snapshots.create.path,
        id: routes.study.snapshots.create.id,
        handle: { parentId: routes.study.snapshots.root.id },
        element: (
          <UserRoleGate canSnapshotsRead canSnapshotsInsert>
            <SnapshotCreatePage />
          </UserRoleGate>
        ),
      },
      {
        element: <StudyApp />,
        children: [
          {
            path: routes.study.instruments.path,
            id: routes.study.instruments.id,
            handle: { parentId: routes.study.view.id },
            element: <StudyInstrumentsPage />,
          },
          {
            path: routes.study.eventDefinitions.path,
            id: routes.study.eventDefinitions.id,
            handle: { parentId: routes.study.view.id },
            element: <StudyEventDefinitionsPage />,
          },
          {
            path: routes.study.nonCrf.path,
            id: routes.study.nonCrf.id,
            handle: { parentId: routes.study.view.id },
            element: <StudyNonCrfPage />,
          },
          {
            path: routes.study.permissions.path,
            id: routes.study.permissions.id,
            handle: { parentId: routes.study.view.id },
            element: <StudyPermissionsPage />,
          },
          {
            path: routes.study.sources.root.path,
            id: routes.study.sources.root.id,
            handle: { parentId: routes.study.view.id },
            element: <SourceRootPage />,
          },
          {
            path: routes.study.stores.root.path,
            id: routes.study.stores.root.id,
            handle: { parentId: routes.study.view.id },
            element: <StoreRootPage />,
          },
          {
            path: routes.study.dataset.path,
            id: routes.study.dataset.id,
            handle: { parentId: routes.study.view.id },
            element: <DatasetRootPage />,
          },
          {
            path: routes.study.auditLogs.path,
            id: routes.study.auditLogs.id,
            handle: { parentId: routes.study.view.id },
            element: (
              <UserRoleGate canAuditLogRead>
                <AuditLogListPage />
              </UserRoleGate>
            ),
          },
          {
            path: routes.study.view.path,
            id: routes.study.view.id,
            handle: { parentId: routes.study.view.id },
            element: <StudyPage />,
          },
          {
            path: ':studyId/*',
            element: <PageNotFound />,
          },
        ],
      },
    ],
  },
];

export const RoutesCrossStudyApp: RouteObject[] = [
  {
    path: routes.crossStudy.root.path,
    id: routes.crossStudy.root.id,
    element: <StudyAppRouterProvider />,
    children: [
      {
        path: routes.crossStudy.dataViewer.path,
        id: routes.crossStudy.dataViewer.id,
        element: <CrossViewerDataPage />,
      },
      {
        path: routes.crossStudy.jobs.root.path,
        id: routes.crossStudy.jobs.root.id,
        element: <JobRootPage />,
      },
      {
        path: routes.crossStudy.jobs.view.path,
        id: routes.crossStudy.jobs.view.id,
        handle: { parentId: routes.crossStudy.jobs.root.id },
        element: <JobEditPage />,
      },
      {
        element: <CrossStudyApp />,
        children: [
          {
            path: routes.crossStudy.sources.root.path,
            id: routes.crossStudy.sources.root.id,
            handle: { parentId: routes.crossStudy.root.id },
            element: <SourceRootPage />,
          },
          {
            path: routes.crossStudy.stores.root.path,
            id: routes.crossStudy.stores.root.id,
            handle: { parentId: routes.crossStudy.root.id },
            element: <StoreRootPage />,
          },
          {
            path: routes.crossStudy.dataset.path,
            id: routes.crossStudy.dataset.id,
            handle: { parentId: routes.crossStudy.root.id },
            element: <DatasetRootPage />,
          },
          {
            path: routes.crossStudy.auditLogs.path,
            id: routes.crossStudy.auditLogs.id,
            handle: { parentId: routes.crossStudy.root.id },
            element: <AuditLogListPage />,
          },
          {
            path: routes.crossStudy.view.path,
            id: routes.crossStudy.view.id,
            handle: { parentId: routes.crossStudy.root.id },
            element: <CrossStudyPage />,
          },
          {
            path: ':studyId/*',
            element: <PageNotFound />,
          },
        ],
      },
    ],
  },
];

if (process.env.APP_MODE === 'GL') {
  const { RoutesAnalysisObjects, RoutesAnalysisPackage } = require('../library/RoutesLibraryApp');
  const targetRoute = RoutesStudyApp.find((route) => route.id === appStudyProviderId);
  if (targetRoute) {
    const lastChild = targetRoute.children?.at(-1);
    if (lastChild) {
      lastChild.children?.push(...RoutesAnalysisPackage);
    }
    targetRoute.children?.push(...RoutesAnalysisObjects);
  }
}
