import { selectStudyUserPermissions, selectStudyUserPermissionsReady } from '@modules/study/duck/studySelectors';
import { selectAuthUser } from '@modules/auth/duck/AuthSelector';
import { GPDIP_FEATURE_NAME } from '@config/constants';
import { useSelector } from 'react-redux';

export const useStudyPermissions = () => {
  const userPermissions = useSelector(selectStudyUserPermissions);
  const userPermissionsReady = useSelector(selectStudyUserPermissionsReady);

  return {
    userPermissions,
    userPermissionsReady,
  };
};

export const useFeatures = () => {
  const user = useSelector(selectAuthUser);

  const hasFeature = (featureName: string) => !!user?.feature_flag.includes(featureName);

  return {
    gpdip: hasFeature(GPDIP_FEATURE_NAME),
  };
};
