import { appReducer } from '@app/duck/appSlice';
import { modelReducer } from '@modules/model/duck/modelSlice';
import { authReducer } from '@modules/auth/duck/AuthSlice';
import { studyReducer } from '@modules/study/duck/studySlice';
import { jobReducer } from '@modules/job/duck/jobSlice';
import { appApi } from '@config/appApi';
import { sourceReducer } from '@modules/source/duck/sourceSlice';
import { storeReducer } from '@modules/stores/duck/storeSlice';
import { modelEditorReducer } from '@modules/modelEditor/duck/modelEditorSlice';
import { viewerReducer, ViewerStore } from '@modules/viewer/duck/viewerSlice';
import { auditReducer } from '@modules/audit/duck/auditSlice';
import type { LibraryRootStage } from '@modules/library/libraryStore';
import { notificationsReducer } from '@app/components/Notifications/duck/notificationsSlice';
import { gpdipReducer } from '@modules/gpdip/duck/gpdipSlice';
import {
  createTransform,
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
  createMigrate,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

// we don't need to persist modals, otherwise they will be auto-opened during rehydration
const filterModals = createTransform(
  (inboundState, key) => {
    const { modals, ...rest } = inboundState as Partial<ViewerStore>;
    return rest;
  },
  (outboundState, key) => {
    return { ...outboundState, modals: { selectedModal: null }, leftSide: {}, snapshot: null };
  },
  { whitelist: ['viewer'] },
);

const persistConfig = {
  key: 'root-datastage',
  storage,
  transforms: [filterModals],
  whitelist: ['viewer'],
  version: 2,
  migrate: createMigrate(
    {
      1: (state) => ({ ...state, viewer: undefined } as any),
      2: (state) => ({ ...state, viewer: undefined } as any),
    },
    { debug: false },
  ),
};

let appReducers = {
  app: appReducer,
  auth: authReducer,
  study: studyReducer,
  model: modelReducer,
  modelEditor: modelEditorReducer,
  source: sourceReducer,
  store: storeReducer,
  job: jobReducer,
  viewer: viewerReducer,
  audit: auditReducer,
  notifications: notificationsReducer,
  gpdip: gpdipReducer,
  [appApi.reducerPath]: appApi.reducer,
};

if (process.env.APP_MODE === 'GL') {
  appReducers = { ...appReducers, ...require('@modules/library/libraryStore').libraryReducers };
}

const reducers = combineReducers(appReducers);

const persistedReducer = persistReducer(persistConfig, reducers);

function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    preloadedState,
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [
            FLUSH,
            REHYDRATE,
            PAUSE,
            PERSIST,
            PURGE,
            REGISTER,
            'auth/setUser',
            'modelEditor/onNodesChange',
            'modelEditor/addNodes',
            'modelEditor/init',
          ],
          ignoredPaths: ['api.mutations', 'auth.user', 'modelEditor.nodes'],
        },
      }).concat(appApi.middleware),
  });
}

export const store = setupStore();

export type RootState = ReturnType<typeof reducers> & LibraryRootStage;
export type AppStore = ReturnType<typeof setupStore>;

export type AppDispatch = AppStore['dispatch'];
