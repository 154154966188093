export enum ModelEditorModalsType {
  'relationSettings' = 'relationSettings',
  'transformSettings' = 'transformSettings',
  'schemaSettings' = 'schemaSettings',
  'unionSettings' = 'unionSettings',
  'joinSettings' = 'joinSettings',
  'groupBySettings' = 'groupBySettings',
  'pivotSettings' = 'pivotSettings',
  'unpivotSettings' = 'unpivotSettings',
  'resultSettings' = 'resultSettings',
  'customSqlSettings' = 'customSqlSettings',
}
