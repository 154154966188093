import { PageTemplateSimple } from '@components';
import { CrossStudyDetails } from '@modules/study/components';

const crossStudyDetails = {
  id: 0,
  name: 'SETUPS',
  summary: 'SETUPS allow user to view and fill Reference Tables which can be used in any Study',
};

export const CrossStudyPage = () => {
  return (
    <PageTemplateSimple title={{ children: crossStudyDetails.name }} content={{ isLoading: false }}>
      <CrossStudyDetails study={crossStudyDetails} />
    </PageTemplateSimple>
  );
};
