import {
  ModelEditorModalsDropModalPayload,
  ModelEditorModalsPushModalPayload,
  ModelEditorModalsSaveModalPayload,
  ModelEditorModalsState,
} from '@modules/modelEditor/modals';
import {
  ModelEditorEdgeType,
  ModelEditorNodeType,
  ModelEditorSchemaEditorItem,
  ModelEditorValidationError,
} from '@modules/modelEditor/ModelEditorTypes';
import { validateNodes } from '@modules/modelEditor/components/builder/Validator';
import { isSliceDataEqual } from '@shared/utils/ReduxUtils';
import { nanoid } from 'nanoid';
import {
  Node,
  Edge,
  NodeChange,
  applyNodeChanges,
  EdgeChange,
  applyEdgeChanges,
  Connection,
  addEdge,
  MarkerType,
} from 'reactflow';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  invalidateNodeByEdgeId,
  invalidateNodeById,
  exportModelEditorData,
  tableNameGenerator,
} from './modelEditorUtils';

const initialState: ModelEditorStore = {
  modelName: 'EMPTY',
  readOnly: false,
  nodes: [],
  edges: [],
  modals: {},
  errors: [],
  confirmation: {
    opened: false,
  },
  hoveredEdge: null,
};

export const modelEditorSlice = createSlice({
  name: 'modelEditor',
  initialState,
  reducers: {
    init: (
      state,
      action: PayloadAction<{
        nodes: ModelEditorStore['nodes'];
        edges: ModelEditorStore['edges'];
        modelName: string;
        readOnly: boolean;
      }>,
    ) => {
      state.nodes = action.payload.nodes;
      state.edges = action.payload.edges;
      state.modelName = action.payload.modelName;
      state.readOnly = action.payload.readOnly;
      state.errors = validateNodes(exportModelEditorData(state));
    },
    setReadOnly: (state, action: PayloadAction<{ readOnly: boolean }>) => {
      state.readOnly = action.payload.readOnly;
    },
    confirmationOpened: (state, action: PayloadAction<boolean>) => {
      state.confirmation.opened = action.payload;
    },
    onNodesChange: (state, action: PayloadAction<NodeChange[]>) => {
      state.nodes = applyNodeChanges(action.payload, state.nodes);
    },
    onEdgesChange: (state, action: PayloadAction<EdgeChange[]>) => {
      state.edges = applyEdgeChanges(action.payload, state.edges);
      state.errors = validateNodes(exportModelEditorData(state));
    },
    onConnect: (state, action: PayloadAction<Connection>) => {
      state.edges = addEdge(
        {
          ...action.payload,
          type: ModelEditorEdgeType.button,
          markerEnd: { type: MarkerType.ArrowClosed },
        },
        state.edges,
      );
      state.errors = validateNodes(exportModelEditorData(state));
    },
    onHoverEdge: (state, action: PayloadAction<ModelEditorStore['hoveredEdge']>) => {
      state.hoveredEdge = action.payload;
    },
    onDeleteNode: (state, action: PayloadAction<string>) => {
      const clearedNodes = invalidateNodeById(action.payload, state.nodes, state.edges);
      state.nodes = clearedNodes.filter((node) => node.id !== action.payload);
      state.edges = state.edges.filter((edge) => edge.source !== action.payload && edge.target !== action.payload);
      state.errors = validateNodes(exportModelEditorData(state));
    },
    onDeleteNodes: (state) => {
      state.nodes = [];
      state.edges = [];
    },
    onDeleteEdge: (state, action: PayloadAction<string>) => {
      state.nodes = invalidateNodeByEdgeId(action.payload, state.nodes, state.edges);
      state.edges = state.edges.filter((edge) => edge.id !== action.payload);
      state.errors = validateNodes(exportModelEditorData(state));
    },
    setNodes: (state, action: PayloadAction<Node[]>) => {
      state.nodes = action.payload;
      state.errors = validateNodes(exportModelEditorData(state));
    },
    selectEdge: (state, action: PayloadAction<{ id: string; selected?: boolean }>) => {
      const { id, selected = true } = action.payload;
      state.edges = state.edges.map((edge) =>
        edge.id !== id
          ? edge
          : {
              ...edge,
              selected,
            },
      );
    },
    addNodes: (state, action: PayloadAction<Node | Node[]>) => {
      const nodesToAdd = Array.isArray(action.payload) ? action.payload : [action.payload];
      state.nodes = state.nodes.concat(
        nodesToAdd.map((node) => ({
          ...node,
          data: {
            ...node.data,
            tableName:
              node.data.tableName ?? tableNameGenerator(state.modelName, node.type as ModelEditorNodeType, node.id),
          },
        })),
      );
      state.errors = validateNodes(exportModelEditorData(state));
    },
    saveStage: (state, action: PayloadAction<ModelEditorModalsSaveModalPayload>) => {
      const { nodeId, data } = action.payload;
      let invalidationRequiredWithId: string | null = null;
      state.nodes = state.nodes.map((node) => {
        if (node.id === nodeId) {
          const { isCheckingNeeded = false, ...clearNodeData } = { ...node.data };

          if (!isSliceDataEqual(node.data, data!)) {
            invalidationRequiredWithId = nodeId;
          }

          return {
            ...node,
            data: {
              ...clearNodeData,
              ...data,
              tableName: node.data.tableName ?? tableNameGenerator(state.modelName, node.data.type, node.id),
            },
          };
        }
        return node;
      });
      if (invalidationRequiredWithId) {
        state.nodes = invalidateNodeById(invalidationRequiredWithId, state.nodes, state.edges, true);
      }
      state.errors = validateNodes(exportModelEditorData(state));
    },
    setErrors: (state, action: PayloadAction<ModelEditorValidationError[]>) => {
      state.errors = action.payload;
    },
    validate: (state, action: PayloadAction) => {
      state.errors = validateNodes(exportModelEditorData(state));
    },
    initSchemaEditor: (state, action: PayloadAction<ModelEditorSchemaEditorItem[]>) => {
      state.schemaEditor = action.payload.map((item) => ({
        ...item,
        key: item.key || nanoid(),
      }));
    },
    moveSchemaEditorRow: (state, action: PayloadAction<{ dragIndex: number; hoverIndex: number }>) => {
      const { dragIndex, hoverIndex } = action.payload;
      const deletedItem = state.schemaEditor![dragIndex];
      state.schemaEditor!.splice(dragIndex, 1);
      state.schemaEditor!.splice(hoverIndex, 0, deletedItem);
      state.schemaEditor = state.schemaEditor!.map((data, index) => ({ ...data, index }));
    },
    switchSchemaEditorDeletedAll: (state, action: PayloadAction<boolean>) => {
      state.schemaEditor = state.schemaEditor!.map((item) => ({ ...item, deleted: action.payload }));
    },
    insertSchemaEditorRow: (state) => {
      state.schemaEditor?.push({
        key: nanoid(),
        name: '',
        type: '',
        index: state.schemaEditor!.length,
        custom: true,
        nullable: true,
      });
    },
    changeSchemaEditorItem: (state, action: PayloadAction<{ index: number; field: string; value: any }>) => {
      const { field, index, value } = action.payload;
      state.schemaEditor![index] = { ...state.schemaEditor![index], [field]: value };
    },
    destroySchemaEditor: (state) => {
      delete state.schemaEditor;
    },
    pushModal: (state, action: PayloadAction<ModelEditorModalsPushModalPayload>) => {
      const { type, callback, data } = action.payload;
      state.modals = { ...state.modals, [type]: { callback, data } };
    },
    dropModal: (state, action: PayloadAction<ModelEditorModalsDropModalPayload>) => {
      const { type } = action.payload;
      const modals = { ...state.modals };
      delete modals[type];
      state.modals = modals;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const modelEditorActions = modelEditorSlice.actions;

export const modelEditorReducer = modelEditorSlice.reducer;

export interface ModelEditorStore {
  modelName: string;
  readOnly: boolean;
  modals: ModelEditorModalsState;
  nodes: Node<any, string | undefined>[];
  edges: Edge<any>[];
  schemaEditor?: ModelEditorSchemaEditorItem[];
  errors: ModelEditorValidationError[];
  confirmation: { opened: boolean };
  hoveredEdge: Edge | null;
}
