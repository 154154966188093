import { TabProps } from '@modules/job/JobTypes';
import { Alert, Button, Checkbox, FormItem, Select, Space, Table, Typography } from '@ui';
import { AntdIconBox } from '@components/icons';
import { emptyError } from '@modules/job/modals/components/upload/duck/uploadConstants';
import { isSystemColumn, typeOptions } from '@modules/job/modals/components/upload/duck/uploadUtils';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { TableColumnsType } from 'antd';
import { css, CSSObject } from '@emotion/react';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const DiscoverTabManual = ({
  t,
  tab,
  canImportJobEditStructure,
  updateTabPartially,
  handleTabConfirm,
}: DiscoverTabProps) => {
  const { tableName, mapping, isMappingExists } = tab;
  const isDisableMapping = !canImportJobEditStructure && isMappingExists;

  const handlesColumnChange = (record: any, field: string, value?: string) => {
    const updatedMappingTable = mapping.map((el) =>
      (el.sourceColumn || el.sourceMappingColumn) === (record.sourceColumn || record.sourceMappingColumn)
        ? {
            ...el,
            [field]: value || !record[field],
          }
        : el,
    );
    updateTabPartially(tableName, { mapping: updatedMappingTable, confirmed: false, ...emptyError });
  };

  const columns: TableColumnsType<TabProps['mapping'][0]> = useMemo(
    () => [
      {
        width: 40,
        key: 'skip',
        dataIndex: 'skip',
        title: t('uploadRT.mapping.skip'),
        align: 'center',
        render: (_, record) => (
          <Checkbox
            checked={record.skip}
            disabled={isSystemColumn(record.sourceColumn!) || isDisableMapping}
            onChange={() => handlesColumnChange(record, 'skip')}
          />
        ),
      },
      {
        width: 250,
        key: 'sourceColumn',
        dataIndex: 'sourceColumn',
        title: t('uploadRT.mapping.sourceColumn'),
        render: (_, record) => {
          if (!record.sourceMappingColumn) {
            return (
              <Space direction="horizontal">
                {record.sourceColumn}
                <AntdIconBox icon={QuestionCircleOutlined} tip={t('uploadRT.mappingTooltips.columnAbsentInMapping')} />
              </Space>
            );
          }

          if (!record.sourceColumn) {
            return (
              <Space direction="horizontal">
                <Typography.Text type="danger" children={record.sourceMappingColumn} />
                <AntdIconBox
                  icon={QuestionCircleOutlined}
                  tip={
                    canImportJobEditStructure
                      ? t('uploadRT.mappingTooltips.columnAbsentInFileAdmin')
                      : t('uploadRT.mappingTooltips.columnAbsentInFile')
                  }
                  color="danger"
                />
              </Space>
            );
          }

          return record.sourceColumn;
        },
      },
      {
        width: 100,
        key: 'type',
        dataIndex: 'type',
        title: t('uploadRT.mapping.type'),
        render: (_, record) => (
          <FormItem
            name={[tab.tableName, 'structure', record.sourceColumn!]}
            wrapperCol={{ span: 24 }}
            css={cssTableInput}
            label={null}
            rules={[{ required: true, message: '' }]}
          >
            <Select
              options={typeOptions}
              onChange={(value) => handlesColumnChange(record, 'type', value)}
              disabled={isSystemColumn(record.sourceColumn!) || isDisableMapping}
            />
          </FormItem>
        ),
      },
      {
        width: 60,
        key: 'nullable',
        dataIndex: 'nullable',
        title: t('uploadRT.mapping.nullable'),
        align: 'center',
        render: (_, record) => (
          <Checkbox
            checked={record.nullable}
            onChange={() => handlesColumnChange(record, 'nullable')}
            disabled={isSystemColumn(record.sourceColumn!) || isDisableMapping}
          />
        ),
      },
      {
        width: 60,
        key: 'primaryKey',
        dataIndex: 'primaryKey',
        title: t('uploadRT.mapping.primaryKey'),
        align: 'center',
        render: (_, record) => (
          <Checkbox
            checked={record.primaryKey}
            onChange={() => handlesColumnChange(record, 'primaryKey')}
            disabled={isDisableMapping}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableName, typeOptions, isDisableMapping, handlesColumnChange, isSystemColumn],
  );

  return (
    <>
      <div css={cssTabHeaderContainer(tab.isMappingExists)}>
        {tab.isMappingExists && (
          <Alert
            css={cssAlert}
            type="warning"
            message={canImportJobEditStructure ? t('uploadRT.mappingExistsMsgAdmin') : t('uploadRT.mappingExistsMsg')}
          />
        )}
        {!tab.isHideConfirmBtn && (
          <Button
            type="primary"
            onClick={() => {
              handleTabConfirm(tab.tableName);
            }}
          >
            {t('uploadRT.confirmTabBtn')}
          </Button>
        )}
      </div>
      <Table
        css={cssTable}
        key={`${tableName}_mappingTable`}
        rowKey={(item) => `${tableName}_${item.sourceColumn || item.sourceMappingColumn}`}
        size="small"
        bordered
        columns={columns}
        dataSource={tab.mapping}
        pagination={false}
        tableLayout="auto"
        scroll={{ y: 250 }}
      />
    </>
  );
};

const cssTable = css`
  height: 300px;
  max-height: 300px;
  width: 100%;
  min-width: inherit;

  &&&& td.ant-table-cell {
    padding: 4px;
  }

  .ant-table-body {
    overflow-y: auto !important;
  }
`;

const cssTableInput = (): CSSObject => ({
  marginBottom: 0,
  width: '100%',
});

const cssTabHeaderContainer = (isMappingExists: boolean): CSSObject => ({
  width: '100%',
  display: 'flex',
  justifyContent: isMappingExists ? 'space-between' : 'right',
  alignItems: 'center',
  marginBottom: 24,
});

const cssAlert = (): CSSObject => ({
  height: 32,
});

interface DiscoverTabProps {
  t: TFunction;
  tab: TabProps;
  updateTabPartially: (val: string, obj: Partial<TabProps>) => void;
  handleTabConfirm: (val: string) => void;
  canImportJobEditStructure?: boolean;
}
