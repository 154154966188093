import { useColumnSearch } from '@components/ui/table/tableHooks';
import { dateToString } from '@shared/utils/Date';
import { Table, Typography } from '@ui';
import { libraryRoutes } from '@routes/library';
import { Link } from 'react-router-dom';
import { TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { TableProps } from 'antd/lib/table';
import { LibraryStatusTag } from './LibraryStatus';
import { LibraryActionMenu } from './LibraryActionMenu';
import { Library, LibraryListResponse } from '../LibraryTypes';

export const LibraryList = ({ data, loading, pagination, onChange }: LibraryListProps) => {
  const { t } = useTranslation(['libraryRoot']);
  const { getColumnSearchProps, locale } = useColumnSearch<Library>();

  const columns: TableColumnsType<Library> = useMemo(
    () => [
      {
        width: 50,
        title: ' ',
        dataIndex: 'actionMenu',
        render: (_, record) => <LibraryActionMenu record={record} t={t} />,
      },
      {
        title: t('root.table.name'),
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
        onFilter: undefined,
        render: (_, record) => (
          <Link
            to={libraryRoutes.models.root.resolver({ libraryId: record.id })}
            state={{ name: record.name }}
            children={record.name}
          />
        ),
      },
      {
        title: t('root.table.description'),
        render: (_, record) => record.description,
      },
      {
        title: t('root.table.created'),
        dataIndex: 'created_at',
        sorter: (a, b) => 0,
        sortDirections: ['ascend'],
        render: (createdAt: number, record) => (
          <>
            <div>{record.created_by}</div>
            <Typography.Text type="secondary">{dateToString(createdAt)}</Typography.Text>
          </>
        ),
      },
      {
        width: 108,
        title: t('root.table.status'),
        render: (_, record) => <LibraryStatusTag status={record.status} />,
      },
    ],
    [t],
  );

  return (
    <Table
      size="small"
      bordered
      locale={locale}
      columns={columns}
      dataSource={data.items}
      loading={loading}
      rowKey={(item) => item.id}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
      onChange={onChange}
    />
  );
};

interface LibraryListProps {
  data: LibraryListResponse;
  loading?: boolean;
  pagination?: TableProps<LibraryListResponse['items'][0]>['pagination'];
  onChange?: TableProps<LibraryListResponse['items'][0]>['onChange'];
  pageSize?: number;
}
