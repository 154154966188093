import { useGpdipListQuery } from '@modules/gpdip/duck/gpdipApi';
import { prepareTableTreeData } from '@modules/gpdip/duck/gpdipUtils';
import { PageTemplateSimple, RefreshButton } from '@components';
import { FINISHED_STATUSES } from '@modules/gpdip/duck/gpdipConstants';
import { IGpdipTree } from '@modules/gpdip/GpdipTypes';
import { useStatusListener } from '@modules/gpdip/duck/gpdipHooks';
import { Button, Space, TableExtraConfig, TableFiltersConfig, TableSorterConfig } from '@ui';
import { GpdipList } from '@modules/gpdip/components/GpdipList';
import { gpdipActions } from '@modules/gpdip/duck/gpdipSlice';
import { GpdipModalsType } from '@modules/gpdip/modals/GpdipModalsConstants';
import { GpdipModalsController } from '@modules/gpdip/modals/GpdipModalsController';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TablePaginationConfig } from 'antd';

export const GpdipRootPage = () => {
  const { t } = useTranslation(['gpdip', 'datastore']);
  const dispatch = useDispatch();
  const gpdipQuery = useGpdipListQuery();
  const { statuses, isLoading } = useStatusListener();
  const [gpdipData, setGpdipData] = useState<IGpdipTree[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<IGpdipTree['id'][]>([]);
  const [filters, setFilters] = useState<TableFiltersConfig>({});

  const gpdipTreeData = useMemo(() => {
    return prepareTableTreeData(gpdipQuery.data ?? []);
  }, [gpdipQuery.data]);

  useEffect(() => {
    if (gpdipTreeData) {
      setGpdipData(gpdipTreeData);
    }
  }, [gpdipTreeData]);

  useEffect(() => {
    if (statuses.length) {
      const finishedStatuses = statuses.filter((item) => FINISHED_STATUSES.includes(item.status));
      if (finishedStatuses.length) {
        const updatedData =
          gpdipQuery?.data?.map((el) => {
            const key = `${el.type}-${el.id.toString()}`;
            const findFinishStatus = finishedStatuses.find((item) => `${item.type}-${item.id.toString()}` === key);
            if (findFinishStatus) {
              return {
                ...el,
                error: findFinishStatus.error,
                finished_at: findFinishStatus.finished_at,
              };
            }
            return el;
          }) || [];
        const regenerateTree = prepareTableTreeData(updatedData);
        setGpdipData(regenerateTree);
      }
    }

    if (getFilterValue(filters)) {
      setGpdipData(getFilteredData(filters));
    }
  }, [statuses, gpdipQuery.data]);

  const openConfiguration = async () => {
    dispatch(gpdipActions.pushModal({ type: GpdipModalsType.settings }));
  };

  const addExpandedKey = (id: IGpdipTree['id']) => {
    setExpandedKeys((prev) => [...new Set([...prev, id])]);
  };

  const removeExpandedKey = (id: IGpdipTree['id']) => {
    const removedId = expandedKeys?.filter((el) => el !== id);
    setExpandedKeys(removedId);
  };

  const getFilterValue = (filters: TableFiltersConfig) => (filters && filters?.name && filters?.name[0]) || '';

  const getFilteredData = (filters: TableFiltersConfig) => {
    const filterValue = getFilterValue(filters);
    if (filterValue) {
      const filteredDataValues: IGpdipTree[] = gpdipTreeData.map((el) => {
        const filteredChildren = el.children.filter((child) =>
          child.name.toLowerCase().includes(filterValue.toString().toLowerCase()),
        );
        if (filteredChildren.length > 0) {
          addExpandedKey(el.id);
        }
        return {
          ...el,
          children: filteredChildren,
        };
      });
      return filteredDataValues;
    }
    return gpdipTreeData;
  };

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    filters: TableFiltersConfig,
    sorter: TableSorterConfig<IGpdipTree>,
    extra: TableExtraConfig<IGpdipTree>,
  ) => {
    switch (extra.action) {
      case 'filter':
        setFilters({ ...filters });
        const isNoFilters = Object.values(filters).every((el) => !el);
        if (isNoFilters) {
          setGpdipData(gpdipTreeData);
        } else {
          const filteredData = getFilteredData(filters);
          setGpdipData(filteredData);
        }
        break;
    }
  };

  return (
    <PageTemplateSimple
      hideTitleSkeleton
      title={{
        children: t('pageRootName'),
        extra: (
          <Space>
            <RefreshButton onClick={gpdipQuery.refetch} />
            <Button children={t('configuration')} onClick={openConfiguration} />
          </Space>
        ),
      }}
      content={{ isLoading: gpdipQuery.isLoading }}
    >
      {!gpdipQuery.isLoading && (
        <GpdipList
          data={gpdipData || []}
          loading={gpdipQuery.isFetching}
          t={t}
          onChange={onTableChange}
          expandedKeys={expandedKeys}
          addExpandedKey={addExpandedKey}
          removeExpandedKey={removeExpandedKey}
          statuses={statuses}
          statusesLoading={isLoading}
        />
      )}
      <GpdipModalsController />
    </PageTemplateSimple>
  );
};
