import Menu, { MenuProps } from 'antd/es/menu';
import { CSSProperties, Key, ReactNode } from 'react';

type MenuItem = Required<MenuProps>['items'][number];

function getMenuItem(props: GetMenuItemProps): MenuItem {
  return props as MenuItem;
}

interface GetMenuItemProps {
  label: ReactNode;
  key: Key;
  icon?: ReactNode;
  children?: MenuItem[];
  style?: CSSProperties;
  onClick?: () => void;
}

export { Menu, getMenuItem };
