import { selectGlobalStudy } from '@app/duck/appSelectors';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { isCrossStudy } from '@shared/utils/common';
import { Fragment, ReactNode } from 'react';
import { useSelector } from 'react-redux';

enum AppGatewaySectionMode {
  'onlyCrossStudy' = 'onlyCrossStudy',
  'onlyStudy' = 'onlyStudy',
  'onlyGLMode' = 'onlyGLMode',
}

const checkDeniedRender = (globalStudyId: number, modes: Omit<AppGatewaySectionProps, 'children'>) => {
  return Object.keys(modes).some((key) => {
    if (modes[AppGatewaySectionMode[key as keyof typeof AppGatewaySectionMode]] !== true) {
      return false;
    }
    switch (key as keyof typeof AppGatewaySectionMode) {
      case AppGatewaySectionMode.onlyCrossStudy:
        return !isCrossStudy(globalStudyId);
      case AppGatewaySectionMode.onlyStudy:
        return isCrossStudy(globalStudyId);
      case AppGatewaySectionMode.onlyGLMode:
        return process.env.APP_MODE !== 'GL';
      default:
        return false;
    }
  });
};

export const AppGatewaySection = (props: AppGatewaySectionProps): ReactJSXElement => {
  const globalStudy = useSelector(selectGlobalStudy);

  if (checkDeniedRender(globalStudy?.id!, props)) {
    return <Fragment />;
  }

  return props.children as ReactJSXElement;
};

export const AppGatewaySectionRenderProps = (props: AppGatewaySectionRenderPropsProps): ReactJSXElement => {
  const globalStudy = useSelector(selectGlobalStudy);

  return props.children(!checkDeniedRender(globalStudy?.id!, props));
};

type AppGatewaySectionModeType = {
  [key in AppGatewaySectionMode]?: boolean;
};

interface AppGatewaySectionProps extends AppGatewaySectionModeType {
  children: ReactNode;
}

interface AppGatewaySectionRenderPropsProps extends AppGatewaySectionModeType {
  children: (allowToRender: boolean) => ReactJSXElement;
}
