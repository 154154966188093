import { IDatasetListResponse, EDatasetType, IDataset, Scope, StudyScope } from '@modules/dataset/DatasetTypes';
import { Space, Table, Tooltip, Typography } from '@ui';
import { ActorAndInfo, InformationModal } from '@components';
import { dateToString } from '@shared/utils/Date';
import { getCreatedByInfo, isCrossStudy } from '@shared/utils/common';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { DatasetActionButtons } from '@modules/dataset/components/DatasetActionButtons';
import { tableListToOptions } from '@shared/utils/Table';
import { useFeatures } from '@modules/user/duck/userHooks';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { capitalizeFirstLetter } from '@shared/utils/Form';
import React, { useMemo, useState } from 'react';
import { TableColumnsType, TableProps } from 'antd';
import { TFunction } from 'i18next';
import { ColumnType } from 'antd/lib/table';
import { ExportOutlined } from '@ant-design/icons';
import { isArray } from 'lodash';
import { useSelector } from 'react-redux';
import { CSSObject, Theme } from '@emotion/react';

export const DatasetList = ({ data, studyId, loading, pagination, onChange, t }: DatasetListProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<IDatasetListResponse['items'][0]>();
  const crossStudy = isCrossStudy(studyId!);
  const globalStudy = useSelector(selectGlobalStudy);
  const [studiesDetailsModal, setStudiesDetailsModal] = useState<StudyScope[] | null>();

  const { gpdip } = useFeatures();

  const datasetTypes = tableListToOptions(
    data?.map((el) => ({ name: t(`rootTable.typeNames.${el.type}`), id: el.type })),
  );

  const columns: TableColumnsType<IDatasetListResponse['items'][0]> = useMemo(
    () =>
      [
        {
          title: t('rootTable.name'),
          dataIndex: 'name',
          ...getColumnSearchProps('name'),
          onFilter: undefined,
        },
        {
          width: 150,
          title: t('rootTable.type'),
          dataIndex: 'type',
          filters: datasetTypes,
          onFilter: undefined,
          render: (type: EDatasetType) => t(`rootTable.typeNames.${type}`),
        },
        {
          title: t('rootTable.created'),
          dataIndex: 'created_at',
          sorter: () => 0,
          sortDirections: ['ascend'],
          render: (createdAt: number, record: IDataset) => (
            <ActorAndInfo info={dateToString(createdAt)} actor={getCreatedByInfo(record)} />
          ),
        },
        {
          title: t('rootTable.updated'),
          dataIndex: 'updated_at',
          sorter: () => 0,
          sortDirections: ['ascend'],
          render: (updatedAt: number, record: IDataset) => (
            <ActorAndInfo info={dateToString(updatedAt)} actor={getCreatedByInfo(record)} />
          ),
        },
        !crossStudy && {
          title: t('rootTable.scope'),
          dataIndex: 'scope',
          render: (scope: Scope) => {
            if (!scope) {
              return globalStudy?.name;
            }
            if (isArray(scope)) {
              const visibleStudiesList = scope
                .slice(0, 3)
                .map((el) => el.study_name)
                .join(', ');

              return scope.length > 3 ? (
                <button onClick={() => setStudiesDetailsModal(scope)} css={cssActionBtn}>
                  {`${visibleStudiesList}...`}
                </button>
              ) : (
                visibleStudiesList
              );
            }
            return capitalizeFirstLetter(scope);
          },
        },
        gpdip &&
          !crossStudy && {
            width: 90,
            title: t('rootTable.sync'),
            dataIndex: 'syncStates',
            render: (_: any, record: IDataset) => {
              if (record.export.automatic) {
                const getExportInfo = record.export ? (
                  <Tooltip title={t('rootTable.syncExport')}>
                    <ExportOutlined />
                  </Tooltip>
                ) : null;

                return (
                  <Space>
                    {t('rootTable.syncStates.auto')}
                    {getExportInfo}
                  </Space>
                );
              }
              if (!record.export.automatic && !record.export.crontab) {
                return t('rootTable.syncStates.manual');
              }

              return t('rootTable.syncStates.period');
            },
          },
        {
          width: 150,
          align: 'center',
          title: t('rootTable.actions.title'),
          dataIndex: '',
          key: 'actions',
          render: (_: any, record: IDataset) => <DatasetActionButtons record={record} t={t} studyId={studyId} />,
        },
      ].filter((item) => typeof item !== 'boolean') as ColumnType<IDataset>[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [datasetTypes, studyId],
  );

  const renderInfoModalContent =
    studiesDetailsModal?.map((el) => (
      <Typography.Paragraph style={{ marginBottom: 0 }}>{el.study_name}</Typography.Paragraph>
    )) ?? '';

  return (
    <>
      <Table
        bordered
        locale={locale}
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey={(item) => item.id}
        onChange={onChange}
        tableLayout="fixed"
        scroll={{ x: 900 }}
        pagination={pagination}
      />
      {studiesDetailsModal && (
        <InformationModal
          title={t('studiesDetails')}
          message={renderInfoModalContent}
          onClose={() => setStudiesDetailsModal(null)}
          width="500px"
        />
      )}
    </>
  );
};

interface DatasetListProps {
  data?: IDatasetListResponse['items'];
  pagination?: TableProps<IDatasetListResponse['items'][0]>['pagination'];
  onChange?: TableProps<IDatasetListResponse['items'][0]>['onChange'];
  loading?: boolean;
  t: TFunction;
  studyId: number;
}

const cssActionBtn = (theme: Theme): CSSObject => ({
  border: 'none',
  background: 'none',
  color: theme.colorLink,
  cursor: 'pointer',
  textAlign: 'left',
  padding: 0,
});

const cssStudiesList = (): CSSObject => ({
  marginBottom: 0,
  paddingBottom: 0,
});
