import { Button, Checkbox, Space, Typography } from '@ui';
import Scrollbars from 'react-custom-scrollbars-2';
import { useTranslation } from 'react-i18next';
import { CSSObject } from '@emotion/react';
import { useCallback, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { NotificationItem } from './NotificationItem';
import {
  INotification,
  INotificationId,
  useArchiveMutation,
  useReadMutation,
  useUnreadMutation,
} from '../duck/notificationsApi';
import { fetchNotifications } from '../duck/notificationsListeners';

enum EAction {
  read = 'read',
  unread = 'unread',
  archive = 'archive',
  nothing = '',
}

export const NotificationsActivePane = ({ notifications }: NotificationsActivePaneProps) => {
  const { t } = useTranslation(['shared']);
  const [read] = useReadMutation();
  const [unread] = useUnreadMutation();
  const [archive] = useArchiveMutation();
  const [selected, setSelected] = useState<INotificationId[]>([]);
  const [processing, setProcessing] = useState<EAction>(EAction.nothing);

  const handleSelect = useCallback(
    (id: INotificationId) =>
      setSelected((prev) => (prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id])),
    [setSelected],
  );

  const handleSelectAll = useCallback(
    (e: CheckboxChangeEvent) =>
      setSelected((prev) => (e.target.checked ? notifications.map((item) => item.notificationId) : [])),
    [setSelected, notifications],
  );

  const readBatch = (ids: number[]) => read(ids).unwrap();
  const unreadBatch = (ids: number[]) => unread(ids).unwrap();
  const archiveBatch = (ids: number[]) => archive(ids).unwrap();

  const processBatch = (action: EAction) => async () => {
    if (action === EAction.nothing || processing !== EAction.nothing || selected.length === 0) {
      return;
    }

    setProcessing(action);
    try {
      const processor = {
        [EAction.read]: readBatch,
        [EAction.unread]: unreadBatch,
        [EAction.archive]: archiveBatch,
      }[action];
      await processor(selected.map((item) => parseInt(item)));
      await fetchNotifications();
      setSelected([]);
    } catch (e) {
      console.error(e);
    } finally {
      setProcessing(EAction.nothing);
    }
  };

  return (
    <>
      <Scrollbars autoHide={false} autoHeight autoHeightMin={450}>
        {notifications.length === 0 && (
          <div css={cssText}>
            <Typography.Text type="secondary">{t('notification.notificationsNotFound')}</Typography.Text>
          </div>
        )}
        {notifications.map((notification) => (
          <NotificationItem
            key={notification.notificationId}
            notification={notification}
            selected={selected.includes(notification.notificationId)}
            onSelect={handleSelect}
            selectable
          />
        ))}
      </Scrollbars>
      <Space css={cssFooter} direction="horizontal" size={4}>
        <div css={cssFixedElement}>
          <Checkbox
            checked={selected.length > 0 && selected.length === notifications.length}
            indeterminate={selected.length > 0 && selected.length !== notifications.length}
            disabled={!!processing || notifications.length === 0}
            onChange={handleSelectAll}
          >
            {t('notification.footer.selectAll')}
          </Checkbox>
        </div>
        <Button
          type="primary"
          size="small"
          disabled={!!processing || selected.length === 0}
          onClick={processBatch(EAction.read)}
          loading={processing === EAction.read}
        >
          {t('notification.footer.markRead')}
        </Button>
        <Button
          type="primary"
          size="small"
          disabled={!!processing || selected.length === 0}
          onClick={processBatch(EAction.unread)}
          loading={processing === EAction.unread}
        >
          {t('notification.footer.markUnread')}
        </Button>
        <Button
          size="small"
          disabled={!!processing || selected.length === 0}
          onClick={processBatch(EAction.archive)}
          loading={processing === EAction.archive}
        >
          {t('notification.footer.archive')}
        </Button>
      </Space>
    </>
  );
};

const cssFooter = (): CSSObject => ({
  height: '50px',
});

const cssFixedElement = (): CSSObject => ({
  width: '90px',
  textAlign: 'center',
});

const cssText = (): CSSObject => ({
  width: '100%',
  textAlign: 'center',
});

interface NotificationsActivePaneProps {
  notifications: INotification[];
}
