import i18n from '@i18n';
import dayjs from 'dayjs';

const localTimeZone = dayjs.tz.guess();

export const timestampToUNIX = (date: string) => dayjs(date).unix();

export const dateToString = (date?: number | string, isUTCTime?: boolean) => {
  const dayjsFunction = isUTCTime ? dayjs.utc : dayjs;
  const dateFormat = 'YYYY-MM-DD HH:mm';

  if (typeof date === 'string') {
    if (isUTCTime) {
      return dayjsFunction(date).tz(localTimeZone).format(dateFormat);
    }
    return dayjsFunction(date).format(dateFormat);
  }
  if (typeof date === 'number') {
    return dayjsFunction(date * 1000).format(dateFormat);
  }
  return i18n.t('na');
};

export const dataToHumanMonthDayYear = (date?: number) => (date ? dayjs(date).format('MMM DD, YYYY') : i18n.t('na'));

// for utc dates to show them with gmt timezone
export const dateToTimezoneString = (date?: number | string) => {
  const dayjsFunction = dayjs.utc;
  const dateFormat = 'YYYY-MM-DD HH:mm:ss [GMT]';

  if (date) {
    if (typeof date === 'number' && date.toString().length < 13) {
      return dayjsFunction(date * 1000)
        .tz('GMT')
        .format(dateFormat);
    }
    return dayjsFunction(date).tz('GMT').format(dateFormat);
  }
  return i18n.t('na');
};
