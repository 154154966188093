import { IAnalysisPackage } from '@modules/library/analysisPackage/AnalysisPackageTypes';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { useDeployAnalysisPackageMutation } from '@modules/library/analysisPackage/duck/analysisPackageApi';
import { FormLayout, DraggableModal, Form, FormItem, Radio, notification, Skeleton, Alert } from '@ui';
import { ProdLabel } from '@modules/library/analysisPackage/modals';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { selectAuthUser } from '@modules/auth/duck/AuthSelector';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useSelector } from 'react-redux';

const AnalysisPackageModalsDeployAnalysisPackageContent = ({
  data,
  onClose,
  t,
}: AnalysisPackageModalsDeployAnalysisPackageContentProps) => {
  const [form] = Form.useForm();
  const envsQuery = useSupportedEnvsQuery();
  const globalStudy = useSelector(selectGlobalStudy);
  const [deployAnalysisPackage, { isError, error }] = useDeployAnalysisPackageMutation();

  const {
    userPermissions: { canGlDeploymentToProd, canGlDeploymentNotToProd },
  } = useStudyPermissions();

  const errorMessage = (isError && error && 'data' in error && error.data.userMsg) || null;

  const onSubmit = async (values: any) => {
    try {
      if (!values['env']) {
        return notification.error({
          message: t('deployForm.deploymentEnvsRequire'),
        });
      }
      await deployAnalysisPackage({
        env: values.env,
        packageId: data.id,
        studyName: globalStudy?.name!,
      }).unwrap();
      notification.info({
        message: t('deployForm.initiateDeploy', { name: data.version, env: envsQuery.data![values.env]!.label }),
      });
      onClose();
    } catch (e) {
      console.error(`Error while deploying analysis package version ${data.version}`, e);
    }
  };

  const envData = (envsQuery.data && Object.entries(envsQuery.data)) || [];

  return (
    <FormLayout form={form} onCancel={onClose} onSubmit={onSubmit} okText={t('deploy')}>
      <FormItem wrapperCol={{ span: 24 }}>{t('deployForm.description')}</FormItem>
      {envsQuery.isLoading ? (
        <FormItem wrapperCol={{ span: 24 }}>
          <Skeleton active />
        </FormItem>
      ) : envData.length > 0 ? (
        <FormItem name="env" label={t('deployForm.envs')} rules={[{ required: true }]} colon>
          <Radio.Group>
            {envData.map(([env, envData]) => (
              <Radio
                key={env}
                value={env}
                disabled={
                  (envData!.label === ProdLabel && !canGlDeploymentToProd) ||
                  (envData!.label !== ProdLabel && !canGlDeploymentNotToProd)
                }
              >{`${envData!.label} (${envData!.domain})`}</Radio>
            ))}
          </Radio.Group>
        </FormItem>
      ) : (
        <FormItem wrapperCol={{ span: 24 }}>
          <Alert type="warning" message={t('deployForm.noDeploymentsFound')} />
        </FormItem>
      )}
      {errorMessage && (
        <FormItem wrapperCol={{ span: 24 }}>
          <Alert type="error" message={errorMessage} />
        </FormItem>
      )}
    </FormLayout>
  );
};

export const AnalysisPackageModalsDeployAnalysisPackage = ({
  open,
  data,
  onClose,
}: AnalysisPackageModalsDeployAnalysisPackageProps) => {
  const { t } = useTranslation(['analysisPackage']);
  const isView = !!(data as IAnalysisPackage)?.id;

  return (
    <DraggableModal
      width="50%"
      open={open}
      onCancel={onClose}
      title={t('deployForm.title', { version: data?.version })}
      footer={null}
      destroyOnClose
    >
      {open && (
        <AnalysisPackageModalsDeployAnalysisPackageContent data={data} onClose={onClose} t={t} isView={isView} />
      )}
    </DraggableModal>
  );
};
export interface AnalysisPackageModalsDeployAnalysisPackageProps {
  open: boolean;
  data: Pick<IAnalysisPackage, 'id' | 'version' | 'description'>;
  onClose: () => void;
}

interface AnalysisPackageModalsDeployAnalysisPackageContentProps
  extends Pick<AnalysisPackageModalsDeployAnalysisPackageProps, 'data' | 'onClose'> {
  t: TFunction;
  isView: boolean;
}
