import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { Button, notification } from '@ui';
import { initialPage } from '@shared/utils/common';
import { PageTemplateSimple } from '@components';
import { AnalysisObjectModelList } from '@modules/library/analysisObjects/model/components';
import {
  useAnalysisObjectModelFiltersQuery,
  useAnalysisObjectModelListQuery,
  useDeleteAnalysisObjectModelMutation,
} from '@modules/library/analysisObjects/model/duck/analysisObjectModelApi';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { IAnalysisObjectModelListResponse } from '@modules/library/analysisObjects/model/AnalysisObjectModelTypes';
import { EAnalysisObjectModelModalsType } from '@modules/library/analysisObjects/model/modals/AnalysisObjectModelModalsConstants';
import { analysisObjectModelActions } from '@modules/library/analysisObjects/model/duck/analysisObjectModelSlice';
import { TLibraryDeleteModelProps } from '@modules/library/model/LibraryModelTypes';
import { ModelModalsController } from '@modules/model/modals';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { QueryErrorType } from '@shared/utils/Error';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const AnalysisObjectModelRoot = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['libraryModel']);
  const supportedEnvsQuery = useSupportedEnvsQuery();
  const globalStudy = useSelector(selectGlobalStudy);
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<IAnalysisObjectModelListResponse['items'][0]>(initialPage);

  const {
    userPermissions: { canGlDmAoImport },
  } = useStudyPermissions();

  const dataModelQuery = useAnalysisObjectModelListQuery({
    detailed: '0',
    page: paginationState.current - 1,
    page_size: paginationState.pageSize,
    ...preparedFilters,
    ...preparedSorter,
  });

  const dataModelFilters = useAnalysisObjectModelFiltersQuery();

  const [deleteDataModel, deleteDataModelQuery] = useDeleteAnalysisObjectModelMutation();

  const pagination = getPagination(dataModelQuery.data?.totalItems);

  const copyDataModal = () =>
    dispatch(analysisObjectModelActions.pushModal({ type: EAnalysisObjectModelModalsType.copyModel }));

  const onDeleteDataModel = async (value: TLibraryDeleteModelProps) => {
    const { id, name } = value;
    try {
      await deleteDataModel(id).unwrap();
      notification.success({ message: t('confirmation.successMessage', { name }) });
    } catch (e) {
      console.error(e);
      notification.error({ message: (e as QueryErrorType).data.userMsg });
    }
  };

  return (
    <PageTemplateSimple
      content={{
        isLoading: dataModelQuery.isLoading && !dataModelQuery.data,
        errorText: t('loadingError'),
        error: dataModelQuery.error,
      }}
      title={{
        children: t('pageRootName'),
        extra: canGlDmAoImport && <Button children={t('import')} onClick={copyDataModal} />,
      }}
    >
      {dataModelQuery.data && (
        <AnalysisObjectModelList
          data={dataModelQuery.data}
          stores={dataModelFilters?.data?.stores ?? []}
          sources={dataModelFilters?.data?.sources ?? []}
          studyId={globalStudy?.id!}
          onChange={onTableChange}
          pagination={pagination}
          loading={dataModelQuery.isFetching}
          onDeleteDataModel={onDeleteDataModel}
          loadingDelete={deleteDataModelQuery.isLoading}
          supportedEnvs={supportedEnvsQuery.data || {}}
          t={t}
        />
      )}
      <ModelModalsController />
    </PageTemplateSimple>
  );
};
