import { SourceList } from '@modules/source/components/SourceList';
import { initialPage, isCrossStudy } from '@shared/utils/common';
import { Source } from '@modules/source/SourceTypes';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { useConnectionListQuery, useSourceListPaginatedQuery } from '@modules/source/duck/sourceApi';
import { PageTemplateSimple } from '@components';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { SourceModalsType } from '@modules/source/modals';
import { sourceActions } from '@modules/source/duck/sourceSlice';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const SourceRootPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['datasource']);
  const globalStudy = useSelector(selectGlobalStudy);
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<Source>(initialPage);
  const connectionListQuery = useConnectionListQuery();

  const {
    userPermissions: { canDataSourceInsert, canDataSourceUpdate },
    userPermissionsReady,
  } = useStudyPermissions();

  const sourceQuery = useSourceListPaginatedQuery({
    page: paginationState.current - 1,
    ...preparedFilters,
    ...preparedSorter,
  });

  // const { navigation } = useRccNavigation({ pageType: 'studies' });

  const addSourceModal = () => {
    dispatch(sourceActions.pushModal({ type: SourceModalsType.saveSource }));
  };

  const pagination = getPagination(sourceQuery.data?.totalItems);

  const isShowAddBtn = canDataSourceInsert && !isCrossStudy(globalStudy?.id!);

  return (
    <PageTemplateSimple
      // navigation={navigation}
      hideTitleSkeleton
      title={{
        children: t('pageRootName'),
        extra: isShowAddBtn && <Button children={t('add')} onClick={addSourceModal} />,
      }}
      content={{
        isLoading: sourceQuery.isLoading && !sourceQuery.data && userPermissionsReady,
        errorText: t('loadingError'),
        error: sourceQuery.error,
      }}
    >
      {sourceQuery.data && (
        <SourceList
          data={sourceQuery.data}
          connectionList={connectionListQuery.data}
          studyId={globalStudy?.id!}
          onChange={onTableChange}
          pagination={pagination}
          loading={sourceQuery.isFetching}
          t={t}
        />
      )}
    </PageTemplateSimple>
  );
};
