import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { initialPage } from '@shared/utils/common';
import { librarySqlLabActions } from '@modules/library/sqlLab/duck/librarySqlLabSlice';
import { ELibrarySqlLabModalsType } from '@modules/library/sqlLab/modals';
import { ILibrarySqlLab, TLibrarySqlLabDeleteProps } from '@modules/library/sqlLab/LibrarySqlLabTypes';
import {
  useLibraryDeleteSqlLabMutation,
  useLibrarySqlLabListQuery,
} from '@modules/library/sqlLab/duck/librarySqlLabApi';
import { LibrarySqlLabList } from '@modules/library/sqlLab/components/LibrarySqlLabList';
import { PageTemplateSimple } from '@components';
import { Button, notification } from '@ui';
import { selectGlobalLibrary } from '@app/duck/appSelectors';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const LibrarySqlLabRootPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['librarySqlLab']);
  const supportedEnvsQuery = useSupportedEnvsQuery();
  const globalLibrary = useSelector(selectGlobalLibrary);
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<ILibrarySqlLab>(initialPage);
  const sqlLabReportQuery = useLibrarySqlLabListQuery({
    detailed: '0',
    page: paginationState.current - 1,
    page_size: paginationState.pageSize,
    ...preparedFilters,
    ...preparedSorter,
  });
  const [deleteSqlLab, deleteSqlLabQuery] = useLibraryDeleteSqlLabMutation();

  const pagination = getPagination(sqlLabReportQuery.data?.totalItems);

  const copySqlLab = () => dispatch(librarySqlLabActions.pushModal({ type: ELibrarySqlLabModalsType.copySqlLab }));

  const onDelete = async (value: TLibrarySqlLabDeleteProps) => {
    const { id, name } = value;
    try {
      await deleteSqlLab(id).unwrap();
      notification.success({ message: t('confirmation.successMessage', { name }) });
    } catch (e) {
      console.error(e);
      notification.error({ message: t('confirmation.errorMessage', { name }) });
    }
  };

  return (
    <PageTemplateSimple
      title={{
        children: t('pageRootName'),
        extra: <Button children={t('import')} onClick={copySqlLab} />,
      }}
      content={{
        isLoading: sqlLabReportQuery.isLoading && !sqlLabReportQuery.data,
        errorText: t('loadingError'),
        error: sqlLabReportQuery.error,
      }}
    >
      {!sqlLabReportQuery.isLoading && (
        <LibrarySqlLabList
          data={sqlLabReportQuery.data}
          libraryId={globalLibrary?.id!}
          onChange={onTableChange}
          onDelete={onDelete}
          pagination={pagination}
          loading={sqlLabReportQuery.isFetching}
          loadingDelete={deleteSqlLabQuery.isLoading}
          supportedEnvs={supportedEnvsQuery.data || {}}
          t={t}
        />
      )}
    </PageTemplateSimple>
  );
};
