import {
  PageLayout,
  PageNavigation,
  PageNavigationProps,
  PageSkeleton,
  PageTitle,
  PageTitleProps,
  QueryError,
} from '@components';
import { QueryErrorType } from '@shared/utils/Error';
import { SerializedError } from '@reduxjs/toolkit';
import { ReactNode } from 'react';

export const PageTemplateSimple = ({
  content,
  navigation,
  title,
  children,
  hideTitleSkeleton,
}: PageTemplateSimpleProps) => {
  if (content?.error) {
    return (
      <PageLayout>
        <QueryError error={content.error} title={content.errorText} />
      </PageLayout>
    );
  }

  return (
    <PageLayout navigation={navigation && <PageNavigation {...navigation} />}>
      <PageTitle {...title} children={title.children} />
      {content?.isLoading && <PageSkeleton hideTitle={hideTitleSkeleton} />}
      {children}
    </PageLayout>
  );
};

interface PageTemplateSimpleProps {
  content?: {
    isLoading?: boolean;
    error?: QueryErrorType | SerializedError;
    errorText?: string | null;
  };
  title: PageTitleProps;
  hideTitleSkeleton?: boolean;
  navigation?: PageNavigationProps;
  children?: ReactNode;
}
