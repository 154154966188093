import Form, { FormItemProps as AntdFormItemProps, FormProps } from 'antd/es/form';
import { CSSObject } from '@emotion/react';
import { ReactNode, useState } from 'react';
import { Alert, Button, Space } from '../';

const FormLayout = ({
  onSubmit,
  onCancel,
  errors,
  children,
  okText,
  cancelText,
  extraActions,
  labelCol,
  wrapperCol,
  submitIsDisabled,
  cancelIsDisabled,
  isDangerous,
  hideOkBtn,
  hideCancelBtn,
  readOnly,
  disabled,
  ...props
}: Omit<FormProps, 'onFinish' | 'children'> & FormLayoutProps) => {
  const [isLoading, setIsLoading] = useState(false);

  let actions = null;

  if (readOnly) {
    actions = (
      <Space full justify="end" css={props.customActionStyle}>
        <Button onClick={onCancel} disabled={isLoading || cancelIsDisabled}>
          {cancelText || 'Close'}
        </Button>
      </Space>
    );
  } else {
    actions = (
      <Space full justify="end" css={props.customActionStyle}>
        {extraActions}
        {!hideCancelBtn && (
          <Button onClick={onCancel} disabled={isLoading || cancelIsDisabled}>
            {cancelText || 'Cancel'}
          </Button>
        )}
        {!hideOkBtn && (
          <Button
            type="primary"
            danger={isDangerous}
            onClick={props.form!.submit}
            loading={isLoading}
            disabled={submitIsDisabled}
          >
            {okText || 'Save'}
          </Button>
        )}
      </Space>
    );
  }

  return (
    <Form
      labelCol={labelCol || { span: 8 }}
      wrapperCol={wrapperCol || { span: 16 }}
      disabled={readOnly || disabled}
      {...props}
      onFinish={async (values) => {
        setIsLoading(true);
        try {
          await onSubmit(values);
        } catch (error) {
          console.error(error);
        }
        setIsLoading(false);
      }}
      labelWrap={true}
    >
      {children as ReactNode}
      {Array.isArray(errors) && errors.length > 0 && (
        <Space css={cssAlertErrors} direction="vertical" full>
          {errors.map((err, key) => (
            <Alert style={{ width: '100%' }} key={key} message={err} type="error" />
          ))}
        </Space>
      )}
      {actions}
    </Form>
  );
};

const FormItem = ({ asInfo, full, ...props }: FormItemProps) => (
  <Form.Item css={cssFormItem({ asInfo, full })} {...props} />
);

const cssAlertErrors = () => ({
  marginBottom: '1rem',
});

const cssFormItem = (props: Pick<FormItemProps, 'asInfo' | 'full'>) => ({
  marginBottom: props.asInfo ? 0 : undefined,
  width: props.full ? '100%' : undefined,
});

export { Form, FormLayout, FormItem };

interface FormItemProps extends AntdFormItemProps {
  full?: boolean;
  asInfo?: boolean;
}

interface FormLayoutProps {
  extraActions?: ReactNode;
  okText?: string | null;
  cancelText?: string;
  children: ReactNode;
  submitIsDisabled?: boolean;
  cancelIsDisabled?: boolean;
  isDangerous?: boolean;
  onCancel: () => void;
  onSubmit: (values: any) => void;
  errors?: string[];
  hideOkBtn?: boolean;
  hideCancelBtn?: boolean;
  customActionStyle?: CSSObject;
  readOnly?: boolean;
}
