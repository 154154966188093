import { Button, Checkbox, Collapse, Modal, Space, Typography } from '@ui';
import { InfoCircleOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import { ReactNode, useState } from 'react';
import { CSSObject } from '@emotion/react';
import Scrollbars from 'react-custom-scrollbars-2';

export const InformationModal = ({
  title,
  userFriendlyErrorMessage,
  message,
  onConfirm,
  onClose,
  width,
  notShowAgainFlag,
  autoHeightMin,
}: IInformationModalProps) => {
  const [checked, setChecked] = useState(false);

  const onCheckboxChange = (e: { target: { checked: boolean } }) => setChecked(e.target.checked);

  const renderMessage = () => (
    <Scrollbars
      css={cssErrorLayout}
      autoHide={false}
      autoHeight
      autoHeightMin={autoHeightMin || (userFriendlyErrorMessage ? 300 : 500)}
    >
      {typeof message === 'string' ? <pre css={cssError}>{message}</pre> : message}
    </Scrollbars>
  );

  return (
    <Modal
      destroyOnClose
      footer={null}
      onCancel={onClose}
      zIndex={1100}
      open
      title={
        <div css={cssTitle}>
          <InfoCircleOutlined />
          {title ?? t('information')}
        </div>
      }
      width={width || '800px'}
    >
      {userFriendlyErrorMessage ? (
        <>
          <Typography.Paragraph
            ellipsis={{ rows: 10, expandable: true, symbol: 'more' }}
            css={cssUserFriendlyErrorMessage}
          >
            {userFriendlyErrorMessage}
          </Typography.Paragraph>
          <Collapse css={cssCollapse}>
            <Collapse.Panel header="Details" key="1">
              {renderMessage()}
            </Collapse.Panel>
          </Collapse>
        </>
      ) : (
        renderMessage()
      )}

      <Space justify="space-between" full>
        <div>
          {notShowAgainFlag && (
            <Checkbox onChange={onCheckboxChange} checked={checked}>
              {t('dontShowAgain')}
            </Checkbox>
          )}
        </div>
        <Space css={cssActions}>
          <Button onClick={onClose}>{t('close')}</Button>
          {onConfirm && (
            <Button type="primary" onClick={() => onConfirm(checked)}>
              {t('ok')}
            </Button>
          )}
        </Space>
      </Space>
    </Modal>
  );
};

const cssErrorLayout = (): CSSObject => ({
  height: '500px',
});

const cssError = (): CSSObject => ({
  fontSize: '12px',
  overflow: 'visible',
  textWrap: 'wrap',
});

const cssTitle = (): CSSObject => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});

const cssActions = (): CSSObject => ({
  textAlign: 'end',
});

const cssUserFriendlyErrorMessage = (): CSSObject => ({
  padding: '16px 0',
});

const cssCollapse = (): CSSObject => ({
  border: 'none',
  marginBottom: '1em',

  '& .ant-collapse-item': {
    border: 'none',
  },
  '& .ant-collapse-content': {
    border: 'none',
    background: 'transparent',
  },
});

interface IInformationModalProps {
  message: ReactNode;
  userFriendlyErrorMessage?: string;
  title?: string;
  onConfirm?: (dontShowAgainChecked: boolean) => void;
  onClose: () => void;
  width?: string;
  notShowAgainFlag?: boolean;
  autoHeightMin?: number;
}
