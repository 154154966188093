import { appApi } from '@config/appApi';
import {
  IAnalysisObjectCDRQuery,
  IAnalysisObjectCDRListQueryParams,
  IAnalysisObjectCDRListResponse,
  TAnalysisObjectCDRSourcesResponse,
} from '@modules/library/analysisObjects/cdr/AnalysisObjectCDRTypes';
import { TagDescription } from '@reduxjs/toolkit/query/react';
import { ANALYSIS_OBJECT_CDR_TAG_DESCRIPTION } from './analysisObjectCDRConstants';

export const AnalysisObjectCDRApiRoutes = {
  list: '/api/library/analysis/cdr',
  cdr: (cdrId: number) => `/api/library/analysis/cdr/${cdrId}`,
  filters: '/api/library/analysis/cdr/filters',
};

const AnalysisObjectCDRInvalidations: {
  LIST: TagDescription<ANALYSIS_OBJECT_CDR_TAG_DESCRIPTION.LIST>;
  ID: (id: number) => TagDescription<ANALYSIS_OBJECT_CDR_TAG_DESCRIPTION.ID>;
  FILTERS: TagDescription<ANALYSIS_OBJECT_CDR_TAG_DESCRIPTION.FILTERS>;
} = {
  LIST: { type: ANALYSIS_OBJECT_CDR_TAG_DESCRIPTION.LIST, id: 'LIST' },
  ID: (modelId: number) => ({ type: ANALYSIS_OBJECT_CDR_TAG_DESCRIPTION.ID, modelId }),
  FILTERS: { type: ANALYSIS_OBJECT_CDR_TAG_DESCRIPTION.FILTERS, id: 'FILTERS' },
};

export const AnalysisObjectCDRApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    analysisObjectCDRList: builder.query<IAnalysisObjectCDRListResponse, IAnalysisObjectCDRListQueryParams | void>({
      providesTags: [AnalysisObjectCDRInvalidations.LIST],
      query: (params) => ({
        params,
        url: AnalysisObjectCDRApiRoutes.list,
      }),
    }),
    analysisObjectCDRFilters: builder.query<TAnalysisObjectCDRSourcesResponse, void>({
      providesTags: [AnalysisObjectCDRInvalidations.FILTERS],
      query: (params) => ({
        params,
        url: AnalysisObjectCDRApiRoutes.filters,
      }),
    }),
    deleteAnalysisObjectCDR: builder.mutation<void, number>({
      invalidatesTags: (request, error, id) => [
        AnalysisObjectCDRInvalidations.LIST,
        AnalysisObjectCDRInvalidations.ID(id),
      ],
      query: (id) => ({
        method: 'DELETE',
        url: AnalysisObjectCDRApiRoutes.cdr(id),
      }),
    }),
    copyAnalysisObjectCDR: builder.mutation<void, IAnalysisObjectCDRQuery>({
      invalidatesTags: [AnalysisObjectCDRInvalidations.LIST],
      query: ({ data, source }) => ({
        params: { source },
        data,
        method: 'POST',
        url: AnalysisObjectCDRApiRoutes.list,
      }),
    }),
  }),
});

export const {
  useAnalysisObjectCDRListQuery,
  useAnalysisObjectCDRFiltersQuery,
  useDeleteAnalysisObjectCDRMutation,
  useCopyAnalysisObjectCDRMutation,
} = AnalysisObjectCDRApi;
