import i18n from '@i18n';
import routes from '@routes';
import { downloadByUrl } from '@shared/utils/File';
import { notification } from '@components/ui';
import { store } from '@store';
import { viewerActions } from '@modules/viewer/duck/viewerSlice';
import { DownloadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ReactNode, createElement } from 'react';
const libraryRoutes = require('@routes/library/RoutesLibrary').default;

const composeStoreMessage = (details: TStoreEventDetails, fallbackMessage?: TMessage): TMessage => {
  switch (details.action) {
    case 'data-store:file-processed':
      return { error: false, text: i18n.t('notification.store.fileProcessed', { name: details.data.name }) };
    case 'data-store:file-errored':
      return { error: true, text: i18n.t('notification.store.fileFailed', { name: details.data.name }) };
    case 'data-store:table-deleted':
      return { error: false, text: i18n.t('notification.store.tableDeleted', { name: details.data.name }) };
    default:
      return fallbackMessage || { error: false, text: i18n.t('notification.store.unknownAction', { id: details.id }) };
  }
};

const composeModelMessage = (details: TModelEventDetails, fallbackMessage?: TMessage): TMessage => {
  switch (details.action) {
    case 'data-model:run-finished':
      return { error: false, text: i18n.t('notification.model.finished', { name: details.data.name }) };
    default:
      return (
        fallbackMessage || {
          error: false,
          text: i18n.t('notification.model.unknownAction', { name: details.data.name }),
        }
      );
  }
};

const composeExistingMessage = (event: TEventWithMessage): TMessage => {
  const isError = ('error' in event.data && !!event.data.error) || event.message.toLowerCase().includes('error');
  return { error: isError, text: event.message };
};

const composeMessage = (extra: string): TMessage => {
  const event = JSON.parse(extra) as TEvent;
  const existingMessage = event.message ? composeExistingMessage(event as TEventWithMessage) : undefined;

  switch (event.event) {
    case 'datastage:data-store-event':
      return composeStoreMessage(event.data, existingMessage);
    case 'datastage:data-model-event':
      return composeModelMessage(event.data, existingMessage);
    default:
      return existingMessage || { error: false, text: i18n.t('notification.unknownEvent') };
  }
};

const composeLink = (extra: string): string => {
  const event = JSON.parse(extra) as TEvent;
  switch (event.event) {
    case 'datastage:deployment-event':
      return libraryRoutes?.deploymentPlans?.resolver({ studyId: event.data.study_id });
    case 'datastage:data-store-event':
      if (event.data.action === 'data-store:table-deleted') {
        return routes.study.dataViewer.resolver({ studyId: event.data.study_id });
      }
      if (event.data.id) {
        return routes.study.jobs.view.resolver({ studyId: event.data.study_id, jobId: event.data.id });
      }
      return '';
    case 'datastage:data-model-event':
      return routes.study.dataViewer.resolver({ studyId: event.data.study_id, tableId: event.data.data.name });
    case 'datastage:table-export-event':
      return routes.study.dataViewer.resolver({ studyId: event.data.study_id, tableId: event.data.data.table_name });
    default:
      return '';
  }
};

export const composeAction = (extra: string): TAction | undefined => {
  const event = JSON.parse(extra) as TEvent;
  switch (event.event) {
    case 'datastage:table-export-event':
      if (event.data.action === 'file-export:finished') {
        return {
          title: i18n.t('notification.export.download'),
          icon: createElement(DownloadOutlined),
          onClick: () => {
            store.dispatch(
              viewerActions.removeTask({ study_id: event.data.study_id, table_id: event.data.data.table_name }),
            );
            notification.info({
              message: i18n.t('notification.export.downloadStarted', {
                file: event.data.data.file_name,
                study: event.data.study_name ?? '',
              }),
            });
            downloadByUrl(`/api/download/${event.data.file_path}`);
          },
        };
      }

      if (event.data.action === 'file-export:errored') {
        return {
          title: i18n.t('notification.export.showError'),
          icon: createElement(QuestionCircleOutlined),
          onClick: () => {
            store.dispatch(
              viewerActions.removeTask({ study_id: event.data.study_id, table_id: event.data.data.table_name }),
            );
            notification.error({
              message:
                event.message ??
                i18n.t('notification.export.failed', {
                  table: event.data.data.table_name,
                  study: event.data.study_name ?? '',
                }),
              description: event.data.error,
            });
          },
        };
      }

      return undefined;
    case 'datastage:data-store-event':
      if (event.data.action === 'data-store:file-errored') {
        return {
          title: i18n.t('notification.store.showError'),
          icon: createElement(QuestionCircleOutlined),
          onClick: () => {
            notification.error({
              message: i18n.t('notification.store.errorTitle'),
              description: event.data.error,
            });
          },
        };
      }

      return undefined;
    default:
      return undefined;
  }
};

export const useMessage = () => ({ composeMessage, composeLink, composeAction });

type TMessage = {
  error: boolean;
  text: string | ReactNode;
};

type TAction = {
  title: string;
  onClick: () => void;
  icon?: ReactNode;
};

type TEvent = (TStoreEvent | TModelEvent | TExportEvent | TDeploymentEvent) & { message?: string };
type TEventWithMessage = TEvent & { message: string };

type TStoreEvent = {
  event: 'datastage:data-store-event';
  data: TStoreEventDetails;
};

type TDeploymentEvent = {
  event: 'datastage:deployment-event';
  data: TDeploymentEventDetails;
};

type TModelEvent = {
  event: 'datastage:data-model-event';
  data: TModelEventDetails;
};

type TExportEvent = {
  event: 'datastage:table-export-event';
  data: TExportEventDetails;
};

type TStoreEventDetails = {
  action: 'data-store:file-errored' | 'data-store:file-processed' | 'data-store:table-deleted';
  id: number;
  study_id: number;
  error?: string;
  data: {
    id: number;
    name: number;
  };
};

type TModelEventDetails = {
  action: 'data-model:run-finished';
  id: number;
  study_id: number;
  data: {
    id: number;
    name: string;
  };
};

type TExportEventDetails = {
  action: 'file-export:finished' | 'file-export:errored';
  file_path: string;
  study_id: number;
  study_name: string;
  error?: string;
  data: {
    file_name: string;
    table_name: string;
  };
};

type TDeploymentEventDetails = {
  action: 'deployment:finished' | 'deployment:errored';
  env: string;
  id: number;
  package_id: number;
  package_version: string;
  study_id: number;
  study_name: string;
};
