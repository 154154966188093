import { LibraryRootPage } from '@modules/library/root/containers/LibraryRootPage';
import { LibraryAppRouterProvider } from '@modules/library/root/LibraryAppRouterProvider';
import { LibraryApp } from '@modules/library/root/LibraryApp';
import { LibraryModelRootPage } from '@modules/library/model/containers/LibraryModelRootPage';
import { LibraryCdrRootPage } from '@modules/library/cdr/containers/LibraryCdrRootPage';
import { LibrarySqlLabRootPage } from '@modules/library/sqlLab/containers/LibrarySqlLabRootPage';
import { LibraryNotebookRootPage } from '@modules/library/notebook/containers/LibraryNotebookRootPage';
import { LibraryAuditLogListPage } from '@modules/library/audit/containers';
import { AnalysisObjectModelRoot } from '@modules/library/analysisObjects/model/containers';
import { AnalysisPackageDeployments, AnalysisPackageRootPage } from '@modules/library/analysisPackage/containers';
import { StudyAnalysisObjectsApp } from '@modules/study/StudyAnalysisObjectsApp';
import { PageNotFound, Redirect } from '@components';
import { AnalysisObjectCDRRoot } from '@modules/library/analysisObjects/cdr/containers';
import { UserRoleGate } from '@modules/user/UserRoleGate';
import { RouteObject } from 'react-router-dom';
import { libraryRoutes } from '.';

export const RoutesLibraryApp: RouteObject[] = [
  {
    path: libraryRoutes.root.path,
    id: libraryRoutes.root.id,
    element: <LibraryRootPage />,
    caseSensitive: true,
  },
  {
    path: libraryRoutes.root.path,
    id: 'library-provider',
    element: <LibraryAppRouterProvider />,
    children: [
      {
        element: <LibraryApp />,
        children: [
          {
            path: libraryRoutes.view.path,
            id: libraryRoutes.view.id,
            handle: { parentId: libraryRoutes.view.id },
            element: <Redirect URLResolver={libraryRoutes.models.root.resolver} />,
          },
          {
            path: libraryRoutes.models.root.path,
            id: libraryRoutes.models.root.id,
            handle: { parentId: libraryRoutes.view.id },
            element: <LibraryModelRootPage />,
          },
          {
            path: libraryRoutes.cdr.root.path,
            id: libraryRoutes.cdr.root.id,
            handle: { parentId: libraryRoutes.view.id },
            element: <LibraryCdrRootPage />,
          },
          {
            path: libraryRoutes.sqlLab.root.path,
            id: libraryRoutes.sqlLab.root.id,
            handle: { parentId: libraryRoutes.view.id },
            element: <LibrarySqlLabRootPage />,
          },
          {
            path: libraryRoutes.notebook.root.path,
            id: libraryRoutes.notebook.root.id,
            handle: { parentId: libraryRoutes.view.id },
            element: <LibraryNotebookRootPage />,
          },
          {
            path: libraryRoutes.audit.root.path,
            id: libraryRoutes.audit.root.id,
            handle: { parentId: libraryRoutes.view.id },
            element: <LibraryAuditLogListPage />,
          },
          {
            path: ':libraryId/*',
            element: <PageNotFound />,
          },
        ],
      },
    ],
  },
];

export const RoutesAnalysisObjects = [
  {
    element: <StudyAnalysisObjectsApp />,
    children: [
      {
        path: libraryRoutes.analysisObjectsModels.root.path,
        id: libraryRoutes.analysisObjectsModels.root.id,
        handle: { parentId: libraryRoutes.analysisObjects.id },
        element: (
          <UserRoleGate canGlDmAoRead>
            <AnalysisObjectModelRoot />
          </UserRoleGate>
        ),
      },
      {
        path: libraryRoutes.analysisObjectsCdr.root.path,
        id: libraryRoutes.analysisObjectsCdr.root.id,
        handle: { parentId: libraryRoutes.analysisObjects.id },
        element: (
          <UserRoleGate canGlCdrAoRead>
            <AnalysisObjectCDRRoot />
          </UserRoleGate>
        ),
      },
    ],
  },
];

export const RoutesAnalysisPackage = [
  {
    path: libraryRoutes.analysisPackage.path,
    id: libraryRoutes.analysisPackage.id,
    handle: { parentId: libraryRoutes.view.id },
    element: (
      <UserRoleGate canGlApRead>
        <AnalysisPackageRootPage />
      </UserRoleGate>
    ),
  },
  {
    path: libraryRoutes.deploymentPlans.path,
    id: libraryRoutes.deploymentPlans.id,
    handle: { parentId: libraryRoutes.view.id },
    element: (
      <UserRoleGate canGlApRead>
        <AnalysisPackageDeployments />
      </UserRoleGate>
    ),
  },
];
