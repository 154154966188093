import { ECdrType, ILibraryCDRResponse, TLibraryCdrDeleteProps } from '@modules/library/cdr/LibraryCdrTypes';
import { Button, Popconfirm, Space, Table } from '@ui';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { dateToString } from '@shared/utils/Date';
import { ActorAndInfo } from '@components';
import { SupportedEnvs } from '@app/AppTypes';
import { useMemo } from 'react';
import { TFunction } from 'i18next';
import { TableColumnsType, TableProps } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

export const LibraryCdrList = ({
  data,
  loading,
  loadingDelete,
  pagination,
  supportedEnvs,
  onChange,
  onDelete,
  t,
}: CdrReportListProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<any>();

  const columns: TableColumnsType<ILibraryCDRResponse['items'][0]> = useMemo(
    () => [
      {
        title: t('rootTable.name'),
        dataIndex: 'name',
        ...getColumnSearchProps('name'),
        onFilter: undefined,
      },
      {
        width: 150,
        title: t('rootTable.cdrType'),
        dataIndex: 'object_type',
        render: (_, record) => ECdrType[record.object_type],
        // TODO: there is only one type for now. Filters should be added when DNA change it in the api
        // filters: getTableListOption(CDR_TYPES),
        // onFilter: undefined,
      },
      {
        width: 100,
        title: t('rootTable.version'),
        dataIndex: 'version',
        render: (_, record) => record.version,
      },
      {
        title: t('rootTable.source'),
        dataIndex: 'source',
        render: (_, record) => (
          <ActorAndInfo
            info={supportedEnvs[record.env]?.label || t('na')}
            actor={record.source || t('na')}
            actorSize={'sm'}
          />
        ),
      },
      {
        title: t('rootTable.created'),
        dataIndex: 'created_at',
        sorter: () => 0,
        sortDirections: ['ascend'],
        render: (createdAt: number, record) => (
          <ActorAndInfo info={dateToString(createdAt)} actor={record.created_by} />
        ),
      },
      {
        width: 80,
        title: t('delete'),
        dataIndex: 'actionDelete',
        align: 'center',
        render: (_, record) => (
          <Space>
            <Popconfirm
              title={t('confirmation.title')}
              description={t('confirmation.description')}
              okText={t('yes')}
              cancelText={t('no')}
              onConfirm={() => onDelete({ id: record.id, name: record.name })}
            >
              <Button icon={<DeleteOutlined />} loading={loadingDelete} />
            </Popconfirm>
          </Space>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingDelete],
  );

  return (
    <Table
      bordered
      locale={locale}
      columns={columns}
      dataSource={data?.items}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

interface CdrReportListProps {
  libraryId: number;
  data?: ILibraryCDRResponse;
  pagination?: TableProps<ILibraryCDRResponse['items'][0]>['pagination'];
  onChange?: TableProps<ILibraryCDRResponse['items'][0]>['onChange'];
  onDelete: (val: TLibraryCdrDeleteProps) => void;
  loading?: boolean;
  loadingDelete?: boolean;
  pageSize?: number;
  supportedEnvs: SupportedEnvs;
  t: TFunction;
}
