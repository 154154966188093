export interface IGpdip {
  id: number | ObjectType;
  type: ObjectType;
  name: string;
  finished_at: string | null;
  error: string | null;
  export: GpdipExport;
}

export interface GpdipExport {
  automatic: boolean;
  crontab: string;
  crontab_ui_repr: string;
}

export interface IGpdipTree extends IGpdip {
  automaticCount: number;
  manualCount: number;
  periodicallyCount: number;
  successCount: number;
  errorCount: number;
  children: IGpdip[];
}

export type RunStatus = Pick<IGpdip, 'type' | 'id' | 'finished_at' | 'error'> & { status: GPDIP_STATUS };
export type GpdipSynchronization = Pick<IGpdip, 'id' | 'type' | 'export'>;

export interface ISettings {
  separator: string;
}

export type RunExport = Pick<IGpdip, 'type' | 'id'>;

export enum ObjectType {
  model = 'model',
  report = 'report',
  notebook = 'notebook',
  dataset = 'dataset',
}

export enum ObjectTypeLabels {
  model = 'Data Model',
  report = 'CDR Report',
  notebook = 'Python Notebooks',
  dataset = 'Dataset',
}

export enum GPDIP_STATUS {
  'FAILED' = 'failed',
  'PENDING' = 'pending',
  'STARTED' = 'started',
  'FINISHED' = 'finished',
}
