import {
  ModelEditorModalsGroupBySettings,
  ModelEditorModalsGroupBySettingsProps,
  ModelEditorModalsJoinSettings,
  ModelEditorModalsJoinSettingsProps,
  ModelEditorModalsSchemaSettings,
  ModelEditorModalsSchemaSettingsProps,
  ModelEditorModalsTransformation,
  ModelEditorModalsTransformationProps,
  ModelEditorModalsResultSettings,
  ModelEditorModalsResultSettingsProps,
  ModelEditorModalsCustomSqlSettings,
  ModelEditorModalsCustomSqlProps,
} from '@modules/modelEditor/modals/components';
import { selectModelEditorModals } from '@modules/modelEditor/duck/modelEditorSelectors';
import {
  ModelEditorModalsCallbackResultType,
  ModelEditorModalsType,
  TModelEditorModalsType,
} from '@modules/modelEditor/modals';
import { modelEditorActions } from '@modules/modelEditor/duck/modelEditorSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  ModelEditorModalsUnionSettings,
  ModelEditorModalsUnionSettingsProps,
} from './components/ModelEditorModalsUnionSettings';
import {
  ModelEditorModalsPivotSettings,
  ModelEditorModalsPivotSettingsProps,
} from './components/ModelEditorModalsPivotSettings';
import {
  ModelEditorModalsUnPivotSettings,
  ModelEditorModalsUnPivotSettingsProps,
} from './components/ModelEditorModalsUnPivotSettings';

export const ModelEditorModalsController = () => {
  const dispatch = useDispatch();
  const modals = useSelector(selectModelEditorModals);

  const closeModal = (type: TModelEditorModalsType) => () => {
    dispatch(modelEditorActions.dropModal({ type }));
  };

  const closeModalWithCallback = (type: TModelEditorModalsType) => (result?: ModelEditorModalsCallbackResultType) => {
    if (modals && modals[type]?.callback) {
      modals![type]?.callback!(result);
    }
    dispatch(modelEditorActions.dropModal({ type }));
  };

  return (
    <>
      <ModelEditorModalsSchemaSettings
        open={ModelEditorModalsType.schemaSettings in modals}
        data={modals[ModelEditorModalsType.schemaSettings]?.data as ModelEditorModalsSchemaSettingsProps['data']}
        onClose={closeModal(ModelEditorModalsType.schemaSettings)}
      />
      <ModelEditorModalsJoinSettings
        open={ModelEditorModalsType.joinSettings in modals}
        data={modals[ModelEditorModalsType.joinSettings]?.data as ModelEditorModalsJoinSettingsProps['data']}
        onClose={closeModal(ModelEditorModalsType.joinSettings)}
      />
      <ModelEditorModalsTransformation
        open={ModelEditorModalsType.transformSettings in modals}
        data={modals[ModelEditorModalsType.transformSettings]?.data as ModelEditorModalsTransformationProps['data']}
        onClose={closeModalWithCallback(ModelEditorModalsType.transformSettings)}
      />
      <ModelEditorModalsGroupBySettings
        open={ModelEditorModalsType.groupBySettings in modals}
        data={modals[ModelEditorModalsType.groupBySettings]?.data as ModelEditorModalsGroupBySettingsProps['data']}
        onClose={closeModal(ModelEditorModalsType.groupBySettings)}
      />
      <ModelEditorModalsResultSettings
        open={ModelEditorModalsType.resultSettings in modals}
        data={modals[ModelEditorModalsType.resultSettings]?.data as ModelEditorModalsResultSettingsProps['data']}
        onClose={closeModal(ModelEditorModalsType.resultSettings)}
      />
      <ModelEditorModalsUnionSettings
        open={ModelEditorModalsType.unionSettings in modals}
        data={modals[ModelEditorModalsType.unionSettings]?.data as ModelEditorModalsUnionSettingsProps['data']}
        onClose={closeModal(ModelEditorModalsType.unionSettings)}
      />
      <ModelEditorModalsPivotSettings
        open={ModelEditorModalsType.pivotSettings in modals}
        data={modals[ModelEditorModalsType.pivotSettings]?.data as ModelEditorModalsPivotSettingsProps['data']}
        onClose={closeModal(ModelEditorModalsType.pivotSettings)}
      />
      <ModelEditorModalsUnPivotSettings
        open={ModelEditorModalsType.unpivotSettings in modals}
        data={modals[ModelEditorModalsType.unpivotSettings]?.data as ModelEditorModalsUnPivotSettingsProps['data']}
        onClose={closeModal(ModelEditorModalsType.unpivotSettings)}
      />
      <ModelEditorModalsCustomSqlSettings
        open={ModelEditorModalsType.customSqlSettings in modals}
        data={modals[ModelEditorModalsType.customSqlSettings]?.data as ModelEditorModalsCustomSqlProps['data']}
        onClose={closeModal(ModelEditorModalsType.customSqlSettings)}
      />
    </>
  );
};
