import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { APP_HEADER_ID } from './AppHeaderContants';

const getHeaderContainer = () => document.getElementById(APP_HEADER_ID) || null;

export const AppHeaderContent = ({ children }: AppHeaderContentProps) => {
  const [headerContainer, setHeaderContainer] = useState(getHeaderContainer);

  useEffect(() => {
    if (!headerContainer) {
      setHeaderContainer(getHeaderContainer());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!headerContainer) {
    return null;
  }

  return createPortal(children, headerContainer);
};

interface AppHeaderContentProps {
  children: ReactNode;
}
