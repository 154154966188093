import { ILibraryModelResponse, TLibraryDeleteModelProps } from '@modules/library/model/LibraryModelTypes';
import { ActorAndInfo } from '@components';
import { dateToString } from '@shared/utils/Date';
import { Button, Popconfirm, Space, Table } from '@ui';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { SupportedEnvs } from '@app/AppTypes';
import { useMemo } from 'react';
import { TFunction } from 'i18next';
import { TableColumnsType, TableProps } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

export const LibraryModelList = ({
  data,
  loading,
  loadingDelete,
  pagination,
  supportedEnvs,
  onChange,
  onDeleteDataModel,
  t,
}: DataModelListProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<any>();

  const columns: TableColumnsType<ILibraryModelResponse['items'][0]> = useMemo(
    () => [
      {
        title: t('rootTable.name'),
        dataIndex: 'name',
        ...getColumnSearchProps(['meta', 'name']),
        onFilter: undefined,
      },
      {
        width: 100,
        title: t('rootTable.version'),
        dataIndex: 'version',
        render: (_, record) => record.version,
      },
      {
        title: t('rootTable.source'),
        dataIndex: 'source',
        render: (_, record) => (
          <ActorAndInfo
            info={supportedEnvs[record.env]?.label || t('na')}
            actor={record.source || t('na')}
            actorSize={'sm'}
          />
        ),
      },
      {
        title: t('rootTable.dataStore'),
        dataIndex: 'data_store',
        render: (_, record) => record.data_store,
      },
      {
        title: t('rootTable.created'),
        dataIndex: 'created_at',
        sorter: () => 0,
        sortDirections: ['ascend'],
        render: (createdAt: number, record) => (
          <ActorAndInfo info={dateToString(createdAt)} actor={record.created_by} />
        ),
      },
      {
        width: 80,
        title: t('delete'),
        dataIndex: 'actionDelete',
        align: 'center',
        render: (_, record) => (
          <Space>
            <Popconfirm
              title={t('confirmation.title')}
              description={t('confirmation.description')}
              okText={t('yes')}
              cancelText={t('no')}
              onConfirm={() => onDeleteDataModel({ id: record.id, name: record.name })}
            >
              <Button icon={<DeleteOutlined />} loading={loadingDelete} />
            </Popconfirm>
          </Space>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingDelete],
  );

  return (
    <Table
      bordered
      locale={locale}
      columns={columns}
      dataSource={data?.items}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

interface DataModelListProps {
  libraryId: number;
  data?: ILibraryModelResponse;
  pagination?: TableProps<ILibraryModelResponse['items'][0]>['pagination'];
  onChange?: TableProps<ILibraryModelResponse['items'][0]>['onChange'];
  onDeleteDataModel: (val: TLibraryDeleteModelProps) => void;
  loading?: boolean;
  loadingDelete?: boolean;
  pageSize?: number;
  supportedEnvs: SupportedEnvs;
  t: TFunction;
}
