import { useColumnSearch } from '@components/ui/table/tableHooks';
import { ECdrType } from '@modules/library/cdr/LibraryCdrTypes';
import { TableColumnsType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ILibraryImportCDR } from '../components/LibraryImportCDR';

export const useCdrColumns = () => {
  const { getColumnSearchProps, locale } = useColumnSearch<ILibraryImportCDR>();
  const { t } = useTranslation(['libraryCdr']);

  const cdrColumns: TableColumnsType<ILibraryImportCDR> = useMemo(
    () => [
      {
        title: t('rootTable.name'),
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
      },
      {
        title: t('rootTable.cdrType'),
        dataIndex: 'type',
        render: (value: ECdrType) => ECdrType[value],
      },
      {
        title: t('rootTable.version'),
        dataIndex: 'version',
        render: (_, record) => record.version || '-',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return { cdrColumns, locale };
};
